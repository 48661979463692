<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div
      style="
        font-size: 16px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin: 1% 1%;
      "
    >
      <div style="width: 100%">
        <el-form :model="SerachInput" label-width="80px">
          <div style="display: flex; flex-wrap: wrap">
            <el-form-item label="激活时间">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="SerachInput.state"
                placeholder="请选择"
                clearable
                style="width: 100px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="套餐">
              <el-select
                v-model="SerachInput.setMeal"
                placeholder="请选择"
                clearable
                style="width: 100px"
              >
                <el-option
                  v-for="item in optionsSet"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div
            style="
              display: flex;
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-form-item label="">
              <el-select
                v-model="SerachInput.last"
                placeholder="请选择"
                clearable
                style="width: 100px"
                @change="oneTop"
              >
                <el-option
                  v-for="item in optionsLast"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="SerachInput.phone"
                placeholder="请输入"
                style="width: 130px"
                clearable
                @change="inputCh"
              ></el-input>
              <img
                src="../../assets/images/query.png"
                alt=""
                style="margin-left: 5px; width: 49px"
                @click="imaClick"
              />
            </el-form-item>
            <div class="buttons1">
              <el-button @click="currentPage">更新当页数据</el-button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="table-content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        ref="multipleTable"
        style="width: 100%"
      >
        <el-table-column prop="iccid" label="ICCID" width="190">
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="120">
        </el-table-column>
        <el-table-column prop="imsi" label="IMSI" width="160">
        </el-table-column>
        <el-table-column prop="activationDate" label="激活时间" width="110">
        </el-table-column>
        <el-table-column prop="combo" label="套餐"> </el-table-column>
        <el-table-column prop="voiceUse" label="语音使用" width="100">
        </el-table-column>
        <el-table-column prop="flowUse" label="流量使用" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.flowUse != null"
              >{{ parseInt(scope.row.flowUse) }}KB</span
            >
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="updateDate" label="数据更新时间" width="120">
        </el-table-column>
        <el-table-column prop="state" label="当前状态" width="110">
          <template slot-scope="scope">
            <el-button
              size="mini"
              v-if="scope.row.state"
              @click="nowState(scope.$index, scope.row)"
              ><span>{{ scope.row.state }}</span></el-button
            >
            <span v-else>{{ scope.row.state }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="通话记录" width="110">
          <template slot-scope="scope">
            <el-button size="mini" @click="tong(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="白名单">
          <template slot-scope="scope">
            <el-button size="mini" @click="white(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="更新数据" width="110">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >立即更新</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <div class="bottom-left">
        <div
          style="
            margin-left: 25px;
            margin-bottom: 15px;
            font-size: 16px;
            color: #606266;
          "
        >
          流量池1用量:{{ flowUse }}/{{ flowAll }}
        </div>
        <div style="margin-left: 25px; font-size: 16px; color: #606266">
          共计{{ totalCount }}条数据
        </div>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
            <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
          </div>
        </div>
        <div class="buttons">
          <el-button @click="exportList">数据导出</el-button>
          <el-button>数据分析</el-button>
        </div>
      </div>
    </div>
    <!-- 当前状态的弹框 -->
    <div>
      <el-dialog
        title="变更号卡状态"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div style="margin: 15px">
          <!-- <el-radio v-model="radio" label="0">待激活</el-radio> -->
          <el-radio-group v-model="radio" @change="card">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">停用</el-radio>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="quite">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 通话记录的弹框 -->
    <div>
      <el-dialog
        title=""
        :visible.sync="dialogVisibleTong"
        width="40%"
        :before-close="handleCloseTong"
      >
        <div style="margin: 10px 15px">ICCID:{{ baiId }}</div>
        <div style="display: flex">
          <div style="margin: 15px">通话记录</div>
          <div style="small">
            <el-table
              :data="dataTong"
              height="300"
              :row-style="{ background: '#fff' }"
            >
              <el-table-column prop="date" label="对方号码" width="100">
              </el-table-column>
              <el-table-column prop="name" label="类型" width="100">
              </el-table-column>
              <el-table-column prop="date" label="开始时间" width="100">
              </el-table-column>
              <el-table-column prop="date" label="结束时间" width="100">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTong = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleTong = false"
            >确 定</el-button
          >
        </span> -->
      </el-dialog>
    </div>
    <!-- 白名单的弹框 -->
    <div>
      <el-dialog
        title=""
        :visible.sync="dialogVisibleBai"
        width="40%"
        :before-close="handleCloseBai"
      >
        <div style="margin: 10px 15px">ICCID:{{ baiId }}</div>
        <div style="display: flex">
          <div style="margin: 0px 15px 15px 15px; width: 13%">白名单</div>
          <div style="small">
            <div>
              每月允许删除一次，每次最多两个，单卡最多设置5个白名单号码。
            </div>
            <div style="margin-top: 10px">
              <el-checkbox-group
                v-model="zz"
                @change="checkboxChange(zz)"
                :min="0"
                :max="2"
              >
                <el-checkbox
                  v-for="item in checkList"
                  :key="item"
                  :label="item"
                  >{{ item }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-left: 15%; margin-top: 4%">
          <el-button
            type="primary"
            style="margin-right: 19%; background: #d78673; border: #d78673"
            @click="delNum"
            >删除</el-button
          >
          <div>
            <el-input
              v-model="whitePhone"
              placeholder="增加白名单号码"
              style="width: 150px"
              clearable
            ></el-input>
            <el-button
              type="primary"
              @click="whiteClick"
              :disabled="disabled"
              style="margin-left: 10px; background: #d78673; border: #d78673"
              >确定</el-button
            >
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisibleTong = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisibleTong = false"
            >确 定</el-button
          > -->
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      checkList: [],
      zz: [],
      whitePhone: "",
      SerachInput: {},
      overallForm: {
        time: [],
      },
      options: [],
      optionsSet: [
        {
          code: "",
          name: "全部",
        },
        {
          code: "200",
          name: "200分钟",
        },
        {
          code: "50",
          name: "50分钟",
        },
      ],
      optionsLast: [
        {
          code: "1",
          name: "ICCID",
        },
        {
          code: "2",
          name: "手机号码",
        },
      ],
      radio: "",
      radios: "",
      tableData: [],
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      dialogVisible: false,
      dialogVisibleTong: false,
      dialogVisibleBai: false,
      dataTong: [],
      price: null,
      shopType: "",
      iccid: "",
      phoneNum: "",
      oneType: "",
      haoId: null,
      cardSta: null,
      baiId: "",
      hangId: null,
      disabled: false,
      dePhone: "",
      flowAll: "",
      flowUse: "",
      currentArr: [],
    };
  },
  created() {
    this.getList();
    this.getSer();
    this.flow();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    currentPage() {
      let loading = this.$loading({
        lock: true,
        text: "正在更新中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      var str = JSON.stringify(this.currentArr);
      var str3 = str.replace("[", "");
      var str4 = str3.replace("]", "");
      apiShout.getupMoList({ ids: str4 }).then((res) => {
        console.log("更新当页数据：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "成功更新当页数据",
            type: "success",
          });
          this.getList();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    delNum() {
      this.$confirm("是否确定删除此号码?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log('删除的参数：',this.dePhone);
          apiShout
            .getupWhite({ id: this.hangId, phone: this.dePhone, type: 4 })
            .then((res) => {
              console.log("删除号码：", res.data);
              if (res.data.code == 200) {
                setTimeout(() => {
                  this.baiChange();
                  this.$message({
                    message: "成功删除号码",
                    type: "success",
                  });
                }, 9000);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    whiteClick() {
      this.$confirm("是否确定增加此白名单?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var phoneNum = 11 && /^1[0123456789]\d{9}$/;
          var phonereg = 11 && /^0\d{2,3}-?\d{7,8}$/;
          if (
            phonereg.test(this.whitePhone) ||
            phoneNum.test(this.whitePhone)
          ) {
            // this.$message.error("正确");
          } else {
            this.$message.error("请输入正确的电话号码");
            return false;
          }
          apiShout
            .getupWhite({ id: this.hangId, phone: this.whitePhone, type: 1 })
            .then((res) => {
              console.log("增加白名单：", res.data);
              if (res.data.code == 200) {
                this.$message({
                  message: "成功增加白名单",
                  type: "success",
                });
                this.whitePhone = "";
                setTimeout(() => {
                  this.baiChange();
                }, 9000);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    card(val) {
      // console.log('val:',val);
      this.cardSta = val;
    },
    quite() {
      let that = this;
      that
        .$http({
          url: "/too/center/mobile/updateMobileState",
          params: {
            id: that.haoId,
            state: that.cardSta,
          },
          method: "get",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("号卡变更：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible = false;
            this.$message({
              message: "号卡状态变更成功",
              type: "success",
            });
            this.getList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    oneTop(val) {
      // console.log("选择：", val);
      this.oneType = val;
      // this.SerachInput.phone = "";
    },
    inputCh() {
      if (this.oneType == 1) {
        this.iccid = this.SerachInput.phone;
        this.phoneNum = "";
      } else if (this.oneType == 2) {
        this.phoneNum = this.SerachInput.phone;
        this.iccid = "";
      } else {
        this.iccid = "";
        this.phoneNum = "";
      }
    },
    checkboxChange(ib) {
      // console.log("勾选的删除的号码:", ib);
      if (ib.length == 2) {
        this.dePhone = ib[0] + "_" + ib[1];
      } else {
        this.dePhone = ib[0];
      }
    },
    getSer() {
      api.getSelectProduct({ dictCode: "mobilestatus" }).then((res) => {
        // console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.options = res.data.result;
        }
      });
    },
    getList() {
      if (this.oneType == 1) {
        this.iccid = this.SerachInput.phone;
        this.phoneNum = "";
      } else if (this.oneType == 2) {
        this.phoneNum = this.SerachInput.phone;
        this.iccid = "";
      } else {
        this.iccid = "";
        this.phoneNum = "";
      }
      apiShout
        .getmoList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          state: this.SerachInput.state,
          iccid: this.iccid,
          phone: this.phoneNum,
          combo: this.SerachInput.setMeal,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            let array = [];
            this.tableData.forEach((ele) => {
              // console.log("ele:", ele);
              let date = new Date(ele.activationDate);
              let Y = date.getFullYear() + "-";
              let M =
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) + "-";
              let D = date.getDate() + " ";
              let h = date.getHours() + ":";
              let m = date.getMinutes() + ":";
              let s = date.getSeconds();
              ele.activationDate = Y + M + D + h + m + s;
              array.push(ele.id);
            });
            console.log("当前页的id:", array);
            this.currentArr = array;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    imaClick() {
      this.getList();
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/mobile/exportMobileList",
          params: {
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            state: this.SerachInput.state,
            iccid: this.iccid,
            phone: this.phoneNum,
            combo: this.SerachInput.setMeal,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "号卡管理" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    handleEdit(index, row) {
      // console.log('row:',row);
      let loading = this.$loading({
        lock: true,
        text: "正在更新中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      apiShout.getupMobile({ id: row.id }).then((res) => {
        // console.log("更新数据：", res.data);

        if (res.data.code == 200) {
          this.$message({
            message: "成功更新数据",
            type: "success",
          });
          this.getList();
          loading.close();
        } else {
          this.$message.error(res.data.msg);
          loading.close();
        }
      });
    },
    nowState(index, row) {
      // console.log("898989:", index, row);
      this.dialogVisible = true;
      this.haoId = row.id;
    },
    tong(index, row) {
      console.log("通话记录:", index, row);
      this.dialogVisibleTong = true;
      this.baiId = row.iccid;
    },
    white(index, row) {
      // console.log("白名单:", index, row);
      this.dialogVisibleBai = true;
      this.baiId = row.iccid;
      this.hangId = row.id;
      this.baiChange();
    },
    baiChange() {
      apiShout.getserWh({ id: this.hangId }).then((res) => {
        // console.log(" 白名单：", res.data);
        if (res.data.code == 200) {
          this.checkList = res.data.result;
          if (res.data.result.length > 5) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    flow() {
      apiShout.getgrMargin().then((res) => {
        // console.log("流量：", res.data);
        if (res.data.code == 200) {
          this.flowAll = res.data.result.total;
          this.flowUse = res.data.result.use;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseTong() {
      this.dialogVisibleTong = false;
    },
    handleCloseBai() {
      this.dialogVisibleBai = false;
      this.whitePhone = "";
      this.zz = [];
    },
  },
};
</script>

<style lang='less' scoped>
.table-content {
  // padding: 1px 10px 10px 0px;
  margin: 20px 40px 20px 25px;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
// .bottom-fen {
//   margin-top: 30px;
// }
.bottom-left {
  position: relative;
  //   display: flex;
}

// .buttons {
//   .el-button {
//     width: 145px;
//     height: 30px;
//     border-radius: 25px;
//     margin-left: 20px;
//     margin-top: 20px;
//     background: #d78673;
//     color: #ffffff;
//     font-size: 19px;
//     padding: 0;
//     float: right;
//   }
// }
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
.buttons1 {
  .el-button {
    width: 135px;
    height: 30px;
    border-radius: 25px;
    // margin-left: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 17px;
    padding: 0;
    // float: right;
  }
}
// table 表格所有的格式
.tables {
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 16px;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}

.small {
  padding: 10px;
}
/deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #909399;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 28px;
}
/deep/.el-input__icon {
  height: auto;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
::v-deep.el-date-range-picker {
  width: 450px !important;
}
::v-deep.el-picker-panel {
  color: #606266;
  line-height: 30px;
  width: 500px !important;
}
</style>